import React, { useEffect, useState } from "react";
import { Tabs, Select, Row, Col, Grid } from "antd";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
import { getAllBusinessData, selectedBusinessInterfaceAction } from "../../../Store/BusinessSlice";
import BusinessSection from "./BusinessSection";

const { useBreakpoint } = Grid;
const BusinessSectionDB = ({ sectionType }) => {
  console.log({ sectionType });
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  const { data: allBusinessData, businessSelectedLang } = useSelector((state) => state.businessList);
  let filterCategory = businessSelectedLang?.name === "తెలుగు" ? "వాణిజ్యం" : "Business";
  const ALLWORD = businessSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);

  const { Option } = Select;
  useEffect(() => {
    setActiveKey(ALLWORD);
    const selectedCategoriesList = allCategoriesList?.find((item) => item.category_name === filterCategory);
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length) setAllSubCategoriesList([ALLWORD, ...subCategories]);
  }, [allCategoriesList, filterCategory]);

  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
  };

  const handleChange = (value) => {
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);
    dispatch(selectedBusinessInterfaceAction(selectedIngerfaceLang?.[0]));
    // window.scrollTo(window.scrollX, window.scrollY);
    // const payload = {
    //   category_name: 'Business',
    //   news_feed_lang_ids:feedLangPayload,
    //   page: 0,
    //   pageSize: 9
    // };
    //   if(payload?.news_feed_lang_ids?.length)   dispatch(getAllBusinessData(payload));
  };
  console.log("ALLWORD", ALLWORD);
  console.log("ALLWORD", activeKey);
  return (
    <>
      <div
        style={{ position: "relative", top: "10px" }}
        className={
          businessSelectedLang?.name === "తెలుగు" ? "custom-politics-tabs-telugu" : "custom-politics-tabs-english"
        }>
        <Row justify={"space-between"}>
          <Col span={2}>
            <div className="heading headig_technology politics_heading">
              <h1>
                <Link to={`/${filterCategory}`}>{sectionType}</Link>
              </h1>
            </div>
          </Col>
          <Col span={screens.xs ? 12 : 8}>
            <div style={{ textAlign: "end", marginTop: businessSelectedLang?.name === "తెలుగు" ? "-2%" : "0px" }}>
              <span className="viewall_style">
                <Link to={`/${filterCategory}`}>
                  {<TranslateWord key={"VIEWALL"} text={"VIEWALL"} headingStyle={false} lang={businessSelectedLang} />}
                </Link>
              </span>
              &emsp;&emsp;
              {businessSelectedLang && (
                <Select
                  size="small"
                  className="hide-on-small feedDropdown"
                  defaultValue={businessSelectedLang && businessSelectedLang?.interface_lang_id}
                  onChange={handleChange}>
                  {interfaceLanguagesData?.map((lang) => {
                    return (
                      <>
                        <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                      </>
                    );
                  })}
                </Select>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              className={screens.xs ? "custom-tabs-container" : ""}
              moreIcon={
                <span style={{ display: "flex" }}>
                  <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={businessSelectedLang} />
                  &#11206;
                </span>
              }>
              {allSubCategoriesList?.map((tabrecord, index) => {
                const tabKey = tabrecord;
                return (
                  <>
                    <TabPane
                      tab={
                        <span className={businessSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                          {tabrecord}
                        </span>
                      }
                      key={tabKey}>
                      <BusinessSection
                        key={index + tabKey}
                        subCategoryName={activeKey}
                        categoryName={filterCategory}
                        sectionInterfaceLang={businessSelectedLang}
                      />
                    </TabPane>
                  </>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BusinessSectionDB;
