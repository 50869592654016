import React, { useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "@splidejs/react-splide/css";
import { useNavigate } from "react-router-dom";
import { Grid, Avatar } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelsData } from "../../../Store/ChannelsSlice";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
const { useBreakpoint } = Grid;
const ChannelsSection = ({ categoryName, subCategoryName, sectionInterfaceLang }) => {
  console.log("sectionInterfaceLang", sectionInterfaceLang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { data: allChannelsData, status, channelsSelectedLang } = useSelector((state) => state.channelsList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  const gettingData = () => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === channelsSelectedLang?.name;
    });
    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      interface_langage_id: selectedInterfaceLanguage?.interface_lang_id,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 10,
      section_name: categoryName,
    };
    if (categoryName === "Politikos" || categoryName === "పొలిటికోస్‌") {
      payload.section_name = "Politikos";
    }
    dispatch(getAllChannelsData(payload));
  };
  useEffect(() => {
    gettingData();
  }, [dispatch, channelsSelectedLang, selectedfeedLanguage, categoryName]);
  if (status === "loading") {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  const gotoChannelsource = (id) => {
    navigate(`/Channel?channel_id=${id}`);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div>
        <div
          className="homescreen-channel-scroll"
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            scrollBehavior: "smooth",
            paddingBottom: screens.xs ? "-30px" : "15px",
          }}>
          {allChannelsData?.data?.map((channel, index) => (
            <div key={index} style={{ textAlign: "center" }}>
              <div>
                <Avatar
                  onClick={() => {
                    gotoChannelsource(channel?.live_tv_source_id);
                  }}
                  src={channel?.logo_url}
                  alt={`${channel?.tv_name} logo`}
                  className="w-full h-full object-contain"
                  style={{
                    height: "75px",
                    width: "75px",
                    marginLeft: "30px",
                    marginRight: "30px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="text-xs font-medium text-center whitespace-nowrap">{channel.tv_display_name}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ChannelsSection;
