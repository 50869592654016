import "@splidejs/react-splide/css";
import { Card, Grid, Image, Typography, Empty, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { getAllWebstoriesData } from "../../../Store/WebstoriesSlice";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
const { useBreakpoint } = Grid;
const WebstoriesSection = ({ categoryName, sectionInterfaceLang }) => {
  let category = categoryName;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [current, setCurrent] = useState("all");
  const [currentIndex, setCurrentIndex] = useState(0);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );

  const {
    data: allWebstoriesData,
    allWebCategories,
    status,
    error,
    webstoriesSelectedLang,
  } = useSelector((state) => state.webstoriesList);
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  const gettingData = () => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === webstoriesSelectedLang?.name;
    });

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      interface_language_id: webstoriesSelectedLang?.interface_lang_id,
      is_all: true,
      is_category: false,
      is_latest: false,
      is_trending: false,
      login_user_id: loggedInUserData?.user_id,
      news_feed_lang_ids: feedLangPayload, //[60]
      page: 0,
      pageSize: 10,
      section_name: "",
    };
    if (!loggedInUserData?.user_id) {
      payload.login_user_id = null;
    }
    if (categoryName === "అన్నీ" || categoryName === "All") {
      //అన్ని
      payload.is_all = true;
      payload.is_latest = false;
      payload.is_trending = false;
      category = "All";
    } else if (categoryName === "Latest" || categoryName === "తాజా") {
      payload.is_all = false;
      payload.is_latest = true;
      payload.is_trending = false;
      category = "Latest";
    } else if (categoryName === "Trending" || categoryName === "ట్రెండింగ్") {
      payload.is_all = false;
      payload.is_latest = false;
      payload.is_trending = true;
      category = "Trending";
    } else {
      payload.is_all = false;
      payload.is_category = true;
      payload.section_name = categoryName;
    }
    dispatch(getAllWebstoriesData(payload));
  };
  useEffect(() => {
    gettingData();
  }, [webstoriesSelectedLang, selectedfeedLanguage]);
  if (status === "loading") {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
  const gotoWebstoriesWithId = (id) => {
    navigate(`/webstorydetails?param1=${id}&category=${categoryName}`);
    window.scrollTo(0, 0);
  };
  const goToSourceWitAuthorId = (input_user_id) => {
    window.scrollTo(0, 0);
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };
  // if (categoryName === "అన్నీ" || categoryName === "All") {
  //   category = "All";
  // } else if (categoryName === "Latest" || categoryName === "తాజా") {
  //   category = "Latest";
  // } else if (categoryName === "Trending" || categoryName === "ట్రెండింగ్") {
  //   category = "Trending";
  // } else {
  //   category = categoryName;
  // }
  // const webStoriesData =
  //   allWebstoriesData?.data && allWebstoriesData?.data[category] && allWebstoriesData?.data[category].length > 0
  //     ? allWebstoriesData?.data[category]
  //     : [];
  const webStoriesData =
    allWebstoriesData?.data && allWebstoriesData?.data[categoryName] && allWebstoriesData?.data[categoryName].length > 0
      ? allWebstoriesData?.data[categoryName]
      : [];

  const handleScroll = (direction) => {
    const container = document.getElementById("webstory-container");
    if (container) {
      const scrollAmount = 600;
      const scrollPosition =
        direction === "left" ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;
      container.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
    if (direction === "left") {
      setCurrentIndex((prev) => Math.max(0, prev - 1));
    } else {
      setCurrentIndex((prev) => Math.min(webStoriesData?.length - 1, prev + 1));
    }
  };
  return (
    <>
      {status === "idle" && webStoriesData?.length === 0 ? (
        <div className="item">
          <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop: "100px",
                marginBottom: "70px",
              }}>
              <Empty />
              <h4 style={{ color: "white" }}>No data </h4>
            </div>
          </div>
        </div>
      ) : (
        <div className="news-cards-wrapper" style={{ marginTop: screens.xs ? "0px" : "-30px" }}>
          {screens.xs ? (
            ""
          ) : (
            <>
              {currentIndex > 0 && (
                <Button
                  style={{ marginLeft: "10px" }}
                  className="scroll-button left"
                  icon={<LeftOutlined />}
                  onClick={() => handleScroll("left")}
                />
              )}
              {currentIndex < webStoriesData?.length - 6 && ( //-4 instead of -1
                <Button
                  style={{ marginRight: "10px" }}
                  className="scroll-button right"
                  icon={<RightOutlined />}
                  onClick={() => handleScroll("right")}
                />
              )}
            </>
          )}
          <div id="webstory-container" className="news-cards-container">
            {webStoriesData?.map((slide, index) => {
              return (
                <div>
                  <div
                    onClick={() => gotoWebstoriesWithId(slide?.lens_id)}
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      borderRadius: "10px",
                      textAlign: "center",
                      overflowX: "auto",
                      width: "225px",
                      height: "375px",
                      objectFit: "cover",
                      marginRight: "15px",
                    }}>
                    <Image
                      src={slide.url}
                      alt={slide.title || "Story"}
                      layout="fill"
                      objectFit="cover"
                      style={{ filter: "brightness(70%)", width: "225px", height: "375px" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px", // Position dots 10px above the bottom of the image
                        left: "50%", // Center horizontally
                        transform: "translateX(-50%)", // Offset for proper centering
                        display: "flex",
                        flexDirection: "row", // Align dots horizontally
                        gap: "0.5rem", // Add spacing between dots
                        marginTop: "-20px",
                      }}>
                      {[...Array(slide.number_of_images)].map((_, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: "-70px",
                            background: "#D9D9D9",
                            width: "8px",
                            height: "8px",
                            borderRadius: "30px",
                          }}></div>
                      ))}
                    </div>

                    <div
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "20px",
                        width: "100%",
                      }}>
                      <div
                        style={{
                          position: "absolute",
                          height: "40px",
                          bottom: "0px",
                          left: "0px",
                          right: "0px",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          color: "#fff",
                          padding: "10px",
                          borderRadius: "5px",
                          fontSize: "16px",
                        }}>
                        {slide.title}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default WebstoriesSection;
