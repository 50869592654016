import React, { useEffect, useState } from "react";
import { Tabs, Menu, Dropdown, Button, Select, Skeleton, Row, Col, Grid } from "antd";

// import { useGetAllActiveCategoryQuery } from "../../Services/CategoryAPI";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import PoliticsSection from "./PoliticsSection";
import TranslateWord from "../../sharedComponents/TranslateWord";
import { getAllPoliticsData, selectedPoliticsInterfaceAction } from "../../../Store/PoliticsSlice";

const { useBreakpoint } = Grid;
const PoliticsSectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const { data: allPoliticsData, status, error, politicsSelectedLang } = useSelector((state) => state.politicsList);
  console.log("politicsSelectedLang", politicsSelectedLang);

  let filterCategory = politicsSelectedLang?.name === "తెలుగు" ? "రాజకీయాలు" : "Politics";
  const ALLWORD = politicsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);

  const { Option } = Select;
  useEffect(() => {
    setActiveKey(ALLWORD);
    const selectedCategoriesList = allCategoriesList?.find((item) => item.category_name === filterCategory);
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length) setAllSubCategoriesList([ALLWORD, ...subCategories]);
  }, [allCategoriesList, filterCategory]);

  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
  };

  const handleChange = (value) => {
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);
    dispatch(selectedPoliticsInterfaceAction(selectedIngerfaceLang?.[0]));
  };

  return (
    <>
      <div
        style={{ position: "relative", top: "10px" }}
        className={
          politicsSelectedLang?.name === "తెలుగు" ? "custom-politics-tabs-telugu" : "custom-politics-tabs-english"
        }>
        <Row justify={"space-between"}>
          <Col span={2}>
            <div className="heading headig_technology politics_heading">
              <h1>
                <Link to={`/${filterCategory}`}>{sectionType}</Link>
              </h1>
            </div>
          </Col>
          <Col span={screens.xs ? 10 :8}>
            <div style={{ textAlign:'end',marginTop: politicsSelectedLang?.name === "తెలుగు" ? "-2%" : "0px"}}>

            <span className="viewall_style">
                <Link to={`/${filterCategory}`}>
                  {<TranslateWord key={"VIEWALL"} text={"VIEWALL"} headingStyle={false} lang={politicsSelectedLang} />}
                </Link>
              </span>
            
              &emsp; &emsp;
              {politicsSelectedLang && (
                <Select
                  className="hide-on-small feedDropdown"
                  size="small"
                  defaultValue={politicsSelectedLang && politicsSelectedLang?.interface_lang_id}
                  onChange={handleChange}>
                  {interfaceLanguagesData?.map((lang) => {
                    return (
                      <>
                        <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                      </>
                    );
                  })}
                </Select>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              className={screens.xs ? "custom-tabs-container" : ""}
              moreIcon={
                <span style={{ display: "flex" }}>
                  <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={politicsSelectedLang} />
                  &#11206;
                </span>
              }>
              {allSubCategoriesList?.map((tabrecord, index) => {
                const tabKey = tabrecord;
                return (
                  <>
                    <TabPane
                      tab={
                        <span className={politicsSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                          {tabrecord}
                        </span>
                      }
                      key={tabKey}>
                      <PoliticsSection
                        key={index + tabKey}
                        subCategoryName={activeKey}
                        categoryName={filterCategory}
                        sectionInterfaceLang={politicsSelectedLang}
                      />
                    </TabPane>
                  </>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PoliticsSectionDB;
