import React, { useEffect } from "react";
import Routing from "../routing/routing";
import Header from "../Header";
import Footer from "../Footer";
import Modal from "../Modal";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllTrendingStories } from "../../Store/trendingSlice";
import { getAllInterfaceLanguages, selectedInterfaceAction } from "../../Store/interfaceLanguageSlice";
import { getAllBreakingNewsData } from "../../Store/breakingNewsSlice";
import { selectedfeedAction } from "../../Store/feedLanguageSlice";
import { selectedPoliticsInterfaceAction } from "../../Store/PoliticsSlice";
import { selectedEntertainmentInterfaceAction } from "../../Store/EntertainmentSlice";
import { selectedSportsInterfaceAction } from "../../Store/SportsSlice";
import { selectedBusinessInterfaceAction } from "../../Store/BusinessSlice";
import { selectedTechnologyInterfaceAction } from "../../Store/TechnologySlice";

const HomeScreenDashBoard = () => {
  const dispatch = useDispatch();
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const {
    data: interfaceLanguagesData,
    state,
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const { data: feedLanguageData, status, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);

  useEffect(() => {
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((interfaceL) => {
      if (interfaceL?.name == "English") {
        return interfaceL;
      }
    });
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === "English";
    });
    console.log("feedLa", userSelectedFeedLanguages);
    dispatch(selectedfeedAction(userSelectedFeedLanguages));
    dispatch(selectedInterfaceAction(selectedIngerfaceLang?.[0]));
    dispatch(selectedPoliticsInterfaceAction(selectedIngerfaceLang?.[0]));
    dispatch(selectedEntertainmentInterfaceAction(selectedIngerfaceLang?.[0]));
    dispatch(selectedSportsInterfaceAction(selectedIngerfaceLang?.[0]));
    dispatch(selectedBusinessInterfaceAction(selectedIngerfaceLang?.[0]));
    dispatch(selectedTechnologyInterfaceAction(selectedIngerfaceLang?.[0]));
  }, [interfaceLanguagesData]);

  useEffect(() => {
    if (selectedfeedLanguage?.[0]?.newsfeed_lang_id) {
      let feedLangPayload = [];
      if (loggedInUserData?.user_id) {
        feedLangPayload = userSelectedNewsFeedData
          ?.filter((item) => item.selected)
          ?.map((item) => item.newsfeed_lang_id);
      } else {
        feedLangPayload = selectedfeedLanguage?.map((feedL) => {
          return feedL?.newsfeed_lang_id;
        });
      }

      console.log("feedLangPayload", feedLangPayload);

      const trendingpayload = {
        news_feed_lang_ids: feedLangPayload,
        page: 0,
        pageSize: 50,
        login_user_id: loggedInUserData?.user_id,
      };
      const categoryPayload = {
        category_name: "sports",
        news_feed_lang_ids: feedLangPayload ?? [60],
        page: 0,
        pageSize: 9,
      };
      const breakingNewsPayload = {
        news_feed_lang_ids: feedLangPayload,
        page: 0,
        pageSize: 50,
        login_user_id: loggedInUserData?.user_id,
      };
      const politicsPayload = {
        category_name: "Politics",
        news_feed_lang_ids: feedLangPayload ?? [60],
        page: 0,
        pageSize: 9,
      };
      // dispatch(getAllStories());
      dispatch(getAllTrendingStories(trendingpayload));
      dispatch(getAllBreakingNewsData(breakingNewsPayload));
    }
  }, [feedLanguageData, selectedfeedLanguage]);
  // console.log('state',storiesData)
  return (
    <div className="container">
      <Router>
        <Header />
        <div>
          <Routing />
        </div>
        <Footer />
        <Modal />
      </Router>
    </div>
  );
};

export default HomeScreenDashBoard;
