import React, { useEffect, useState } from "react";
import { Tabs, Menu, Dropdown, Button, Select, Skeleton, Row, Col, Grid } from "antd";

// import { useGetAllActiveCategoryQuery } from "../../Services/CategoryAPI";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
import TechnologySection from "./TechnologySection";
import { getAllTechnologyData, selectedTechnologyInterfaceAction } from "../../../Store/TechnologySlice";

const { useBreakpoint } = Grid;
const TechnologySectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const {
    data: allTechnologyData,
    status,
    error,
    technologySelectedLang,
  } = useSelector((state) => state.technologyList);
  console.log("technologySelectedLang", technologySelectedLang);
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  let filterCategory = technologySelectedLang?.name === "తెలుగు" ? "సాంకేతికం" : "Technology";
  const ALLWORD = technologySelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);

  const { Option } = Select;
  useEffect(() => {
    setActiveKey(ALLWORD);
    const selectedCategoriesList = allCategoriesList?.find((item) => item.category_name === filterCategory);
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length) setAllSubCategoriesList([ALLWORD, ...subCategories]);
  }, [allCategoriesList, filterCategory]);
  //  if(politicsLoading === 'loading'){
  //   return <div> <Skeleton
  //   active
  //   title={false}
  //   paragraph={{ rows: 2, width: [1000, 1000] }}
  // /></div>
  // }
  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
  };
  //   const handleChange = (value) => {
  //     setisShowMore(true);
  //     setActiveKey(secondArray[value]);
  //   };
  //   let children = [];
  //   for (let i = 0; i < secondArray.length; i++) {
  //     children.push(<Select.Option key={i}>{secondArray[i]}</Select.Option>);
  //   }
  const handleChange = (value) => {
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);

    dispatch(selectedTechnologyInterfaceAction(selectedIngerfaceLang?.[0]));
    // const payload = {
    //   category_name: 'Technology',
    //   news_feed_lang_ids:feedLangPayload,
    //   page: 0,
    //   pageSize: 9
    // };
    //   if(payload?.news_feed_lang_ids?.length)   dispatch(getAllTechnologyData(payload));

    // dispatch(getAllTechnologyData(politicsPayload));
    // setSelectedInterface(selectedIngerfaceLang);
  };
  console.log("ALLWORD", ALLWORD);
  console.log("ALLWORD", activeKey);
  return (
    <>
      <div
        style={{ position: "relative", top: "10px" }}
        className={
          technologySelectedLang?.name === "తెలుగు" ? "custom-politics-tabs-telugu" : "custom-politics-tabs-english"
        }>
        <Row justify={"space-between"}>
          <Col span={2}>
            <div className="heading headig_technology politics_heading">
              <h1>
                <Link to={`/${filterCategory}`}>{sectionType}</Link>
              </h1>
            </div>
          </Col>
          <Col span={screens.xs ? 12 : 8}>
            <div
              style={{               
                textAlign: "end",
                marginTop: screens.xs ? "-2%" : "0px",marginTop: technologySelectedLang?.name === "తెలుగు" ? "-2%" : "0px"
              }}>
              <span className="viewall_style">
                <Link to={`/${filterCategory}`}>
                  {
                    <TranslateWord
                      key={"VIEWALL"}
                      text={"VIEWALL"}
                      headingStyle={false}
                      lang={technologySelectedLang}
                    />
                  }
                </Link>
              </span>
              &emsp;&emsp;
              {technologySelectedLang && (
                <Select
                  size="small"
                  className="hide-on-small feedDropdown"
                  defaultValue={technologySelectedLang && technologySelectedLang?.interface_lang_id}
                  onChange={handleChange}>
                  {interfaceLanguagesData?.map((lang) => {
                    return (
                      <>
                        <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                      </>
                    );
                  })}
                </Select>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              className={screens.xs ? "custom-tabs-container" : ""}
              moreIcon={
                <span style={{ display: "flex" }}>
                  <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={technologySelectedLang} />
                  &#11206;
                </span>
              }>
              {allSubCategoriesList?.map((tabrecord, index) => {
                const tabKey = tabrecord;
                return (
                  <>
                    <TabPane
                      tab={
                        <span className={technologySelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                          {tabrecord}
                        </span>
                      }
                      key={tabKey}>
                      <TechnologySection
                        key={index + tabKey}
                        subCategoryName={activeKey}
                        categoryName={filterCategory}
                        sectionInterfaceLang={technologySelectedLang}
                      />
                    </TabPane>
                  </>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TechnologySectionDB;
