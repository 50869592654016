import React, { useEffect } from "react";

const AdComponent = ({ adClient, adSlot, adFormat = "auto", fullWidthResponsive = true }) => {
  useEffect(() => {
    // Check if adsbygoogle is available and push only if ads are not already initialized
    if (typeof window.adsbygoogle !== "undefined") {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("AdSense error: ", e.message);
      }
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-format={adFormat}
      data-full-width-responsive={fullWidthResponsive.toString()}></ins>
  );
};

export default AdComponent;
