import React, { useEffect, useState } from "react";
import { Tabs, Menu, Dropdown, Button, Select, Skeleton, Grid, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
import WebstoriesSection from "./WebstoriesSection";
import {
  getAllWebstoriesData,
  getWebstoriescategories,
  selectedWebstoriesInterfaceAction,
} from "../../../Store/WebstoriesSlice";
const { useBreakpoint } = Grid;

const WebstoriesSectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);

  const { webstoriesCategories: allWebCategories, webstoriesSelectedLang } = useSelector(
    (state) => state.webstoriesList
  );
  const ALLWORD = webstoriesSelectedLang?.name === "తెలుగు" ? "అన్నీ" : "All";
  const latest = webstoriesSelectedLang?.name === "తెలుగు" ? "తాజా" : "Latest";
  const trendingLives = webstoriesSelectedLang?.name === "తెలుగు" ? "ట్రెండింగ్" : "Trending";
  const { TabPane } = Tabs;
  const screens = useBreakpoint();
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [selectedLang, setSelectedLang] = useState(webstoriesSelectedLang?.interface_lang_id);
  const [selectedCategory, setSelectedCategory] = useState(ALLWORD);
  const webcategories = [];
  if (allWebCategories && allWebCategories.data && allWebCategories.data.length > 0) {
    allWebCategories.data.forEach((cat) => {
      webcategories.push({ category_name: cat?.lens_category_name });
    });
  }
  webcategories.splice(0, 0, { category_name: ALLWORD }, { category_name: latest }, { category_name: trendingLives });
  const { Option } = Select;

  useEffect(() => {
    if (webstoriesSelectedLang?.name) {
      dispatch(getWebstoriescategories(webstoriesSelectedLang?.name));
      gettingData(ALLWORD);
    }
  }, [webstoriesSelectedLang]);

  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
    setSelectedCategory(key);
    gettingData(key);
  };
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const gettingData = (key) => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === webstoriesSelectedLang?.name;
    });

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }

    const payload = {
      interface_language_id: webstoriesSelectedLang?.interface_lang_id,
      is_all: true,
      is_category: false,
      is_latest: false,
      is_trending: false,
      login_user_id: null,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 10,
      section_name: "",
    };

    if (key === "అన్నీ" || key === "All") {
      payload.is_all = true;
      payload.is_latest = false;
      payload.is_trending = false;
    } else if (key === "Latest" || key === "తాజా") {
      payload.is_all = false;
      payload.is_latest = true;
      payload.is_trending = false;
    } else if (key === "Trending" || key === "ట్రెండింగ్") {
      payload.is_all = false;
      payload.is_latest = false;
      payload.is_trending = true;
    } else {
      payload.is_all = false;
      payload.is_category = true;
      payload.section_name = key;
    }

    dispatch(getAllWebstoriesData(payload));
  };
  const handleChange = (value) => {
    setSelectedLang(value);
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });

    dispatch(selectedWebstoriesInterfaceAction(selectedIngerfaceLang?.[0]));
  };
  useEffect(() => {
    handleChange(selectedInterfaceLanguage?.interface_lang_id);
  }, [selectedInterfaceLanguage]);

  useEffect(() => {
    setActiveKey(ALLWORD);
    setSelectedCategory(ALLWORD);
  }, [webstoriesSelectedLang]);

  return (
    <>
      <div
        style={{ position: "relative", top: "10px" }}
        className={
          webstoriesSelectedLang?.name === "తెలుగు" ? "custom-politics-tabs-telugu" : "custom-politics-tabs-english"
        }>
        <Row justify={"space-between"}>
          <Col span={2}>
            <div className="heading headig_technology politics_heading">
              <h1>
                {
                  <TranslateWord
                    key={"WEB STORIES"}
                    text={"WEB STORIES"}
                    headingStyle={true}
                    lang={webstoriesSelectedLang}
                  />
                }
              </h1>
            </div>
          </Col>
          <Col span={screens.xs ? 10 : 8}>
            <div
              style={{
                textAlign: "end",
                marginTop: screens.xs ? "-2%" : "0px",
                marginTop: webstoriesSelectedLang?.name === "తెలుగు" ? "-2%" : "0px",
              }}>
              <span className="viewall_style">
                <Link to={`AllwebstoriesList`}>
                  {
                    <TranslateWord
                      key={"VIEWALL"}
                      text={"VIEWALL"}
                      headingStyle={false}
                      lang={webstoriesSelectedLang}
                    />
                  }
                </Link>
              </span>
              &emsp;&emsp;
              {webstoriesSelectedLang && (
                <Select
                  size="small"
                  className="hide-on-small feedDropdown"
                  value={selectedLang}
                  onChange={handleChange}>
                  {interfaceLanguagesData?.map((lang) => {
                    return (
                      <>
                        <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                      </>
                    );
                  })}
                </Select>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              className={screens.xs ? "custom-tabs-container" : ""}
              moreIcon={
                <span style={{ display: "flex" }}>
                  <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={webstoriesSelectedLang} />
                  &#11206;
                </span>
              }>
              {webcategories?.map((tabrecord, index) => {
                const tabKey = tabrecord.category_name;
                return (
                  <>
                    <TabPane
                      tab={
                        <span className={webstoriesSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                          {tabKey}
                        </span>
                      }
                      key={tabKey}>
                      <WebstoriesSection
                        key={index + tabKey}
                        subCategoryName={activeKey}
                        categoryName={selectedCategory}
                        sectionInterfaceLang={webstoriesSelectedLang}
                      />
                    </TabPane>
                  </>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default WebstoriesSectionDB;
