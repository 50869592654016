import { Button, Col, Grid, Row, Typography, Modal, message } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ShareAltOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  XIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "swiper/css";
import "swiper/css/navigation"; // For navigation (arrows)
import "swiper/css/pagination"; // For pagination styles
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import beauty from "../imgaes/Webstoryimages/beauty.png";
import beauty_vector from "../imgaes/Webstoryimages/beauty_vector.png";
import celeb from "../imgaes/Webstoryimages/celeb.png";
import celeb_vector from "../imgaes/Webstoryimages/celeb_vector.png";
import defaultVector from "../imgaes/Webstoryimages/defaultVector.png";
import education from "../imgaes/Webstoryimages/education.png";
import education_vector from "../imgaes/Webstoryimages/education_vector.png";
import Election from "../imgaes/Webstoryimages/Election.png";
import Election_vector from "../imgaes/Webstoryimages/Election_vector.png";
import food from "../imgaes/Webstoryimages/food.png";
import food_vector from "../imgaes/Webstoryimages/food_Vector.png";
import health from "../imgaes/Webstoryimages/health.png";
import health_vector from "../imgaes/Webstoryimages/health_vector.png";
import movie from "../imgaes/Webstoryimages/movie.png";
import movie_vector from "../imgaes/Webstoryimages/movie_vector.png";
import sports from "../imgaes/Webstoryimages/sports.png";
import technology from "../imgaes/Webstoryimages/technology.png";
import technology_Vector from "../imgaes/Webstoryimages/technology_Vector.png";
import { addBookmarkWebstory, removeBookmarkWebstory } from "../Store/WebstoriesSlice";
import { shareStoryUrl } from "../utils/config";

import { getWebstoryDetails } from "../Store/WebstoriesSlice";
const { useBreakpoint } = Grid;

const WebstoryDetails = (categoryName) => {
  let category = categoryName;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const textContentRefs = useRef([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedCategory = queryParams.get("category");
  const audioRef = useRef(null);
  const screens = useBreakpoint();
  const [textContentHeights, setTextContentHeights] = useState([]);
  const [containerHeight, setContainerHeight] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [swiperReady, setSwiperReady] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedShareInstance, setSelectedShareInstance] = useState({});
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);

  const {
    data: allWebstoriesData,
    WebstoryRecordDetails: selectedWebstoryData,
    status,
    error,
    webstoriesSelectedLang,
  } = useSelector((state) => state.webstoriesList);
  const webdata = selectedWebstoryData?.data;
  const recordImages = selectedWebstoryData?.data?.images_details;

  const getImageBG = (category) => {
    switch (category) {
      case "Sports":
        return sports;
      case "Food":
        return food;
      case "Entertainment":
        return movie;
      case "Tech":
        return technology;
      case "Education":
        return education;
      case "Healthcare":
        return health;
      case "Celeb":
        return celeb;
      case "beauty":
        return beauty;
      case "Elections":
        return Election;
      case "All":
        return food;
      default:
        return defaultVector;
    }
  };

  const getImageVector = (category) => {
    switch (category) {
      case "Entertainment":
        return movie_vector;
      case "Food":
        return food_vector;
      case "Tech":
        return technology_Vector;
      case "Education":
        return education_vector;
      case "Healthcare":
        return health_vector;
      case "Celeb":
        return celeb_vector;
      case "beauty":
        return beauty_vector;
      case "Elections":
        return Election_vector;
      default:
        return;
    }
  };

  const getVectorHeight = () => {
    const baseSize = 80;
    const calculatedSize = containerHeight * (screens.xs ? 0.12 : 0.12);
    return Math.min(calculatedSize, baseSize);
  };
  const getTitleFontSize = () => {
    const baseFontSize = 36;
    const calculatedFontSize = containerHeight * (screens.xs ? 0.03 : 0.04);
    const fontSize = Math.min(calculatedFontSize, baseFontSize);
    const lineHeight = fontSize * 1;
    return { fontSize, lineHeight };
  };
  const getDescriptionFontSize = () => {
    const baseFontSize = 26;
    const calculatedFontSize = containerHeight * (screens.xs ? 0.02 : 0.025);
    const fontSize = Math.min(calculatedFontSize, baseFontSize);
    const lineHeight = fontSize * 1;
    return { fontSize, lineHeight };
  };

  const getSubmitterFontSize = () => {
    const baseFontSize = 16;
    const calculatedFontSize = containerHeight * (screens.xs ? 0.02 : 0.025);
    return Math.min(calculatedFontSize, baseFontSize);
  };

  const getDateFontSize = () => {
    const baseFontSize = 14;
    const calculatedFontSize = containerHeight * (screens.xs ? 0.018 : 0.02);
    return Math.min(calculatedFontSize, baseFontSize);
  };

  useEffect(() => {
    if (swiperReady) {
      const timer = setTimeout(() => {
        setIsTransitioning(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [activeSlideIndex, swiperReady]);

  const handleSlideChange = (swiper) => {
    setIsTransitioning(false);
    setActiveSlideIndex(swiper.activeIndex);
  };

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const urlParams = new URLSearchParams(window.location.search); // Get query parameters from the URL
  const lensId = urlParams.get("param1");
  useEffect(() => {
    getWebStoryDetails();
  }, [dispatch]);
  const getWebStoryDetails = () => {
    const payload = {
      category_name: selectedCategory,
      lens_id: lensId,
      login_user_id: loggedInUserData?.user_id,
    };
    dispatch(getWebstoryDetails(payload));
  };
  useLayoutEffect(() => {
    const measureHeights = () => {
      const heights = textContentRefs.current.map((ref) => (ref ? ref.offsetHeight : 0));
      setTextContentHeights(heights);
    };

    requestAnimationFrame(measureHeights);
  }, [webdata]);

  useEffect(() => {
    const measureHeights = () => {
      const heights = textContentRefs.current.map((ref) => ref?.offsetHeight || 0);
      setTextContentHeights(heights);
    };

    measureHeights();
    window.addEventListener("resize", measureHeights);

    return () => window.removeEventListener("resize", measureHeights);
  }, [webdata]);
  const handleBookMark = (id) => {
    const payload = {
      id: id,
      login_user_id: loggedInUserData?.user_id,
    };
    dispatch(addBookmarkWebstory(payload)).then((action) => {
      if (addBookmarkWebstory.fulfilled.match(action)) {
        if (action.payload.is_success) {
          getWebStoryDetails();
          message.success({
            content: "Bookmarked successfully",
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
        }
      }
      if (!loggedInUserData?.user_id) {
        message.warning({
          content: "Please Login",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
        return;
      }
    });
  };

  const removeBookmark = async (bookmarked_id) => {
    const login_user_id = loggedInUserData?.user_id;
    const lens_id = bookmarked_id;
    dispatch(removeBookmarkWebstory({ userId: login_user_id, lens_id: bookmarked_id })).then((action) => {
      if (removeBookmarkWebstory.fulfilled.match(action)) {
        if (action.payload.is_success) {
          getWebStoryDetails();
          message.success({
            content: "Removed Bookmark successfully",
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
        }
      }
    });
  };
  const handleBookmarkActions = (lens_id, isUpdate) => {
    if (isUpdate) {
      removeBookmark(lens_id);
    } else {
      handleBookMark(lens_id);
    }
  };

  const handleShare = () => {
    setIsModalVisible(!isModalVisible);
  };
  const updateShareInstance = (record) => {
    setSelectedShareInstance(record);
    handleShare();
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, [webdata?.audio_url]);
  const handleMuteToggle = () => {
    if (webdata?.audio_url) {
      setIsMuted((prev) => !prev);
      if (audioRef.current) {
        audioRef.current.muted = !isMuted;
      }
    }
  };

  return (
    <>
      <Row
        className="mb-8"
        justify="center"
        align="middle"
        style={{
          height: screens.xs ? "55%" : "100%",
          // width: screens.xs ? "" : "100%",
          background: "linear-gradient(to bottom, #dce8f2, #d7d2b8)",
          objectFit: screens.xs ? "cover" : "",
        }}>
        <Col span={16} md={16} lg={16} xl={16} xxl={16}>
          <div>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              modules={[Navigation, Pagination, Autoplay]}
              navigation={screens.xs ? false : true}
              pagination={{ clickable: true }}
              autoplay={{
                delay: 5450,
                disableOnInteraction: false,
              }}
              speed={1000}
              className="webStoriesSwiper"
              onSlideChange={handleSlideChange}
              onInit={() => setSwiperReady(true)}
              style={{ width: "100%", height: "100%", position: "relative" }}>
              {recordImages?.map((slide, storyIndex) => (
                <SwiperSlide
                  key={storyIndex}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ alignItems: "center" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        position: "relative",
                        overflow: "hidden", //exist

                        // maxHeight: "90vh", //n
                        // maxWidth: "100%", //n
                        // width: "100%",
                        // height: "auto", //auto
                        // objectFit: "cover", //
                        // position: "relative",
                        // backgroundColor: "black", //n
                        // overflow: "hidden",
                        // display: "flex", //n
                        // flexDirection: "column", //n
                        // justifyContent: "center", //n
                      }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "-50px",
                          // width: "25%",
                        }}>
                        <div>
                          <Button style={{ backgroundColor: "transparent", borderWidth: "0px" }}>
                            <i
                              class="fa fa-arrow-left"
                              aria-hidden="true"
                              style={{
                                fontSize: "20px",
                                fontWeight: "400",
                                cursor: "pointer",
                                color: "#FFFFFF",
                              }}
                              onClick={() => window.history.back()}
                            />
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            maxWidth: "50%",
                            marginBottom: "-50px",
                          }}>
                          <Button
                            style={{ backgroundColor: "transparent", borderWidth: "0px" }}
                            onClick={() => handleBookmarkActions(webdata?.lens_id, webdata?.is_bookmarked)}>
                            {webdata?.is_bookmarked ? (
                              <i
                                class="fa fa-bookmark"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "red",
                                }}></i>
                            ) : (
                              <i
                                class="fa fa-bookmark-o"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "#FFFFFF",
                                }}></i>
                            )}
                          </Button>
                          {webdata?.audio_url && (
                            <audio ref={audioRef} src={webdata?.audio_url} autoPlay loop muted={isMuted} />
                          )}
                          <Button
                            style={{ backgroundColor: "transparent", borderWidth: "0px" }}
                            onClick={handleMuteToggle}>
                            {webdata?.audio_url ? (
                              isMuted ? (
                                <i
                                  class="fa fa-volume-off"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "20px",
                                    color: "#FFFFFF",
                                  }}></i>
                              ) : (
                                <i
                                  class="fa fa-volume-up"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "20px",
                                    color: "#FFFFFF",
                                  }}></i>
                              )
                            ) : (
                              <i
                                class="fa fa-volume-up"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "#808080",
                                }}></i>
                            )}
                          </Button>
                          <Button
                            style={{ backgroundColor: "transparent", borderWidth: "0px" }}
                            onClick={() => updateShareInstance(selectedWebstoryData?.data)}>
                            {" "}
                            <ShareAltOutlined style={{ fontSize: "20px", fontWeight: "400", color: "white" }} />
                          </Button>
                        </div>
                      </div>
                      <img
                        src={slide?.url}
                        alt={`Slide ${slide.file_id}`}
                        style={{
                          maxHeight: "90vh",
                          width: "auto",
                          borderRadius: "10px",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                          // objectFit: "cover",
                        }}
                      />

                      {/* <img
                        src={slide?.url}
                        alt={`Slide ${slide.file_id}`}
                        style={{
                          maxHeight: "90vh",
                          maxWidth: "100%", // Ensures it doesn't exceed the container width
                          width: "auto", // Maintains aspect ratio
                          height: "auto", // Maintains aspect ratio
                          borderRadius: "10px",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                          backgroundColor: "black", // Black background
                          display: "block", // Centers the image horizontally inside its container
                          margin: "auto", // Centers the image vertically inside its container
                        }}
                      /> */}

                      <div
                        ref={(el) => (textContentRefs.current[storyIndex] = el)}
                        className="textContent"
                        style={{
                          position: "absolute",
                          bottom: 0,
                          zIndex: 1,
                          width: "100%",
                          textAlign: "center",
                        }}>
                        <Row
                          style={{
                            zIndex: 1,
                            padding: "36px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            position: "relative",
                            marginBottom: "-30px",
                          }}>
                          {getImageVector(selectedWebstoryData?.data?.category_name) && (
                            <Col
                              span={24}
                              style={{
                                textAlign: "center",
                                transition: "transform 1s ease, opacity 2s ease",
                                opacity: isTransitioning ? 1 : 0,
                              }}>
                              <img
                                src={getImageVector(selectedWebstoryData?.data?.category_name)}
                                alt="Vector"
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  objectFit: "contain",
                                }}
                              />
                            </Col>
                          )}

                          <Col
                            span={24}
                            style={{
                              transition: "transform 0.6s ease, opacity 0.6s ease",
                              transform: isTransitioning ? "translateY(0)" : "translateY(100%)",
                              opacity: isTransitioning ? 1 : 0,
                              textAlign: "center",
                              marginTop: screens.xs ? "20px" : "0px",
                            }}>
                            <Typography
                              variant="subtitle1"
                              fontWeight={900}
                              fontSize={getTitleFontSize()}
                              style={{
                                lineHeight:
                                  webstoriesSelectedLang?.name === "English"
                                    ? `${getTitleFontSize().lineHeight}px`
                                    : "2.2rem",
                                color: "#fff",
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                textOverflow: "ellipsis",
                                fontFamily: webstoriesSelectedLang?.name === "English" ? "Faustina" : "Mallanna",
                                fontSize: screens.xs ? "18px" : "24px",
                                textAlign: "center",
                                lineHeight: 1,
                              }}
                              color={category === "food" ? "#FF9800" : "#fff"}>
                              {slide?.title}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              fontWeight={400}
                              fontSize={getDescriptionFontSize()}
                              style={{
                                marginTop: "5px",
                                lineHeight:
                                  webstoriesSelectedLang?.name === "English"
                                    ? `${getDescriptionFontSize().lineHeight}px`
                                    : "2rem",
                                color: "#fff",
                                display: "-webkit-box",
                                overflow: "hidden",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                textOverflow: "ellipsis",
                                fontFamily: webstoriesSelectedLang?.name === "English" ? "Faustina" : "Mallanna",
                                textAlign: "center",
                              }}
                              color="#fff">
                              {slide?.description}
                            </Typography>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            zIndex: 1,
                            padding: "0px 16px 19px 25px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                            justifyContent: "space-between",
                            width: "calc(100% - 41px)",
                            opacity: isTransitioning ? 1 : 0,
                            transition: "transform 1s ease, opacity 0.6s ease",
                          }}>
                          <Col>
                            <Typography
                              variant="h5"
                              fontWeight={500}
                              fontSize={getSubmitterFontSize()}
                              color="#fff"
                              style={{
                                fontFamily:
                                  webstoriesSelectedLang?.code !== "en"
                                    ? webstoriesSelectedLang?.code === "te"
                                      ? "Mallanna"
                                      : "noto sans devanagari"
                                    : "Faustina",
                                color: "#fff",
                              }}>
                              {webdata?.submitterName}
                            </Typography>
                            <Typography
                              variant="h5"
                              fontWeight={300}
                              fontSize={getDateFontSize()}
                              color="#fff"
                              style={{
                                paddingTop: "-4px",
                                fontFamily:
                                  webstoriesSelectedLang?.name !== "English"
                                    ? webstoriesSelectedLang?.name !== "English"
                                      ? "Mallanna"
                                      : "noto sans devanagari"
                                    : "Faustina",
                                color: "#fff",
                              }}>
                              {webdata?.date}
                            </Typography>
                          </Col>
                        </Row>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          height: `${textContentHeights[storyIndex]}px`,
                          overflow: "hidden",
                          position: "absolute",
                          bottom: 0,
                          marginBottom: screens.xs ? "-40%" : "0%",
                        }}>
                        <img
                          src={getImageBG(selectedWebstoryData?.data?.category_name)}
                          alt="vector"
                          layout="fill"
                          height={100}
                          width={100}
                          objectFit="fill"
                          style={{ filter: "brightness(70%)" }}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Col>
      </Row>
      <Modal className="share_more_modal" title="Share More" open={isModalVisible} onCancel={handleShare}>
        <div>
          <Row gutter={16}>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <FacebookShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}>
                <FacebookIcon size={25} round /> Facebook
              </FacebookShareButton>
            </Col>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <TwitterShareButton
                url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}
                title={
                  selectedShareInstance?.images_details && selectedShareInstance?.images_details.length > 0
                    ? selectedShareInstance?.images_details[0].title
                    : ""
                }>
                <XIcon size={25} round />
                &nbsp;Twitter
              </TwitterShareButton>
            </Col>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <TelegramShareButton
                url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}
                title={
                  selectedShareInstance?.images_details && selectedShareInstance?.images_details.length > 0
                    ? selectedShareInstance?.images_details[0].title
                    : ""
                }>
                <TelegramIcon size={25} round />
                &nbsp;Telegram
              </TelegramShareButton>
            </Col>
            <Col span={12} style={{ marginBottom: "10px" }}>
              <LinkedinShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.lens_id}`}>
                <LinkedinIcon size={25} round /> Linkedin
              </LinkedinShareButton>
            </Col>
            <Col span={12}>
              <TumblrShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}>
                <TumblrIcon size={25} round /> Tumblr
              </TumblrShareButton>
            </Col>
            <Col span={12}>
              <WhatsappShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}>
                <WhatsappIcon size={25} round /> Whatsapp
              </WhatsappShareButton>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default WebstoryDetails;
