import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";

const initialState = {
  data: [],
  status: "idle",
  error: "",
  sourceData: [],
  channelMoreData: [],
  suggestedAllData: [],
  channelsSelectedLang: {},
  allChannelsData: [],
};

const ChannelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    selectedChannelsInterfaceAction: (state, action) => {
      state.channelsSelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllChannelsData.pending, (state, action) => {
      state.data = [];
      state.status = "loading";
    });
    builder.addCase(getAllChannelsData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllChannelsData.rejected, (state, action) => {
      state.data = [];
      state.status = "error";
    });
    builder.addCase(gotoSourceChannelData.pending, (state, action) => {
      state.sourceData = [];
      state.status = "loading";
    });
    builder.addCase(gotoSourceChannelData.fulfilled, (state, action) => {
      state.sourceData = action?.payload;
      state.status = "idle";
    });
    builder.addCase(gotoSourceChannelData.rejected, (state, action) => {
      state.sourceData = [];
      state.status = "error";
    });
    builder.addCase(gotoChannelMoreData.pending, (state, action) => {
      state.channelMoreData = [];
      state.status = "loading";
    });
    builder.addCase(gotoChannelMoreData.fulfilled, (state, action) => {
      state.channelMoreData = action?.payload;
      state.status = "idle";
    });
    builder.addCase(gotoChannelMoreData.rejected, (state, action) => {
      state.channelMoreData = [];
      state.status = "error";
    });
    builder.addCase(getSuggestedData.pending, (state, action) => {
      state.suggestedAllData = [];
      state.status = "loading";
    });
    builder.addCase(getSuggestedData.fulfilled, (state, action) => {
      state.suggestedAllData = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getSuggestedData.rejected, (state, action) => {
      state.suggestedAllData = [];
      state.status = "error";
    });
    builder.addCase(getAllCategoryChannelsData.pending, (state, action) => {
      state.allChannelsData = [];
      state.status = "loading";
    });
    builder.addCase(getAllCategoryChannelsData.fulfilled, (state, action) => {
      state.allChannelsData = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllCategoryChannelsData.rejected, (state, action) => {
      state.allChannelsData = [];
      state.status = "error";
    });
  },
});

export const getAllChannelsData = createAsyncThunk("channels/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/livetv/channels`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const gotoSourceChannelData = createAsyncThunk("source/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/livetvManagement/gotoSourcechannel`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const gotoChannelMoreData = createAsyncThunk("sourceAll/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/livetvManagement/gotoSourcechannelmore`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const getSuggestedData = createAsyncThunk("suggestedAll/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/livetvManagement/suggestednews`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const getAllCategoryChannelsData = createAsyncThunk("allChannels/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/livetv/allchannels`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const { selectedChannelsInterfaceAction } = ChannelsSlice.actions;
export default ChannelsSlice.reducer;
