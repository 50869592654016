import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
import WebstoryDetails from "../Components/WebstoryDetails";
const initialState = {
  data: [],
  status: "idle",
  error: "",
  webstoriesSelectedLang: {},
  webstoriesCategories: [],
  WebstoryRecordDetails: [],
};

const WebstoriesSlice = createSlice({
  name: "webstories",
  initialState,
  reducers: {
    selectedWebstoriesInterfaceAction: (state, action) => {
      state.webstoriesSelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllWebstoriesData.pending, (state, action) => {
      state.data = [];
      state.status = "loading";
    });
    builder.addCase(getAllWebstoriesData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllWebstoriesData.rejected, (state, action) => {
      state.data = [];
      state.status = "error";
    });
    builder.addCase(getWebstoryDetails.pending, (state, action) => {
      state.WebstoryRecordDetails = [];
      state.status = "loading";
    });
    builder.addCase(getWebstoryDetails.fulfilled, (state, action) => {
      state.WebstoryRecordDetails = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getWebstoryDetails.rejected, (state, action) => {
      state.WebstoryRecordDetails = [];
      state.status = "error";
    });
    builder.addCase(getWebstoriescategories.pending, (state, action) => {
      state.webstoriesCategories = [];
      state.status = "loading";
    });
    builder.addCase(getWebstoriescategories.fulfilled, (state, action) => {
      state.webstoriesCategories = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getWebstoriescategories.rejected, (state, action) => {
      state.webstoriesCategories = [];
      state.status = "error";
    });
    builder.addCase(addBookmarkWebstory.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(addBookmarkWebstory.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(addBookmarkWebstory.rejected, (state, action) => {
      state.status = "error";
    });
    builder.addCase(removeBookmarkWebstory.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(removeBookmarkWebstory.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(removeBookmarkWebstory.rejected, (state, action) => {
      state.status = "error";
    });
  },
});

export const getAllWebstoriesData = createAsyncThunk("webstories/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/webstories/website/listing`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const getWebstoryDetails = createAsyncThunk("webstoriesDetails/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/webstories/forId`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const getWebstoriescategories = createAsyncThunk("webstoriescategories/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/LensCategory/available/${payload}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});
export const addBookmarkWebstory = createAsyncThunk("addBookmarkWebstory/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/webstoriesbookmark`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const removeBookmarkWebstory = createAsyncThunk("removeBookmarkWebstory/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/webstoriesbookmark?login_user_id=${payload.userId}&lens_id=${payload.lens_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});
export const { selectedWebstoriesInterfaceAction } = WebstoriesSlice.actions;
export default WebstoriesSlice.reducer;
