"use client";
import { Col, Grid, Row, Empty, Image, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { getAllWebstoriesData, getWebstoriescategories } from "../Store/WebstoriesSlice";
const { useBreakpoint } = Grid;
const AllwebstoriesList = () => {
  const [category, setCategory] = useState("All");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    webstoriesCategories: allWebCategories,
    data: allWebstoriesData,
    status,
    webstoriesSelectedLang,
  } = useSelector((state) => state.webstoriesList);
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
    return feed?.name === webstoriesSelectedLang?.name;
  });
  let feedLangPayload = [];
  if (loggedInUserData?.user_id) {
    feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
  } else {
    feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
  }
  const ALLWORD = webstoriesSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const latest = webstoriesSelectedLang?.name === "తెలుగు" ? "తాజా" : "Latest";
  const trendingLives = webstoriesSelectedLang?.name === "తెలుగు" ? "ట్రెండింగ్" : "Trending";
  const webcategories = [];
  if (allWebCategories && allWebCategories.data && allWebCategories.data.length > 0) {
    allWebCategories.data.forEach((cat) => {
      webcategories.push({ category_name: cat?.lens_category_name });
    });
  }
  webcategories.splice(0, 0, { category_name: ALLWORD }, { category_name: latest }, { category_name: trendingLives });
  useEffect(() => {
    if (webstoriesSelectedLang?.name) {
      dispatch(getWebstoriescategories(webstoriesSelectedLang?.name));
    }
  }, [webstoriesSelectedLang]);

  const gettingData = (key) => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === webstoriesSelectedLang?.name;
    });
    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      interface_language_id: selectedInterfaceLanguage?.interface_lang_id,
      is_all: true,
      is_category: false,
      is_latest: false,
      is_trending: false,
      login_user_id: null,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 10,
      section_name: "",
    };
    if (key === "అన్ని" || key === "All") {
      payload.is_all = true;
      payload.is_latest = false;
      payload.is_trending = false;
      setCategory("All");
    } else if (key === "Latest" || key === "తాజా") {
      payload.is_all = false;
      payload.is_latest = true;
      payload.is_trending = false;
      setCategory("Latest");
    } else if (key === "Trending" || key === "ట్రెండింగ్") {
      payload.is_all = false;
      payload.is_latest = false;
      payload.is_trending = true;
      setCategory("Trending");
    } else {
      payload.is_all = false;
      payload.is_category = true;
      payload.section_name = key;
      setCategory(key);
    }
    dispatch(getAllWebstoriesData(payload));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    gettingData(category);
  }, [webstoriesSelectedLang, selectedfeedLanguage]);
  const webStoriesData =
    allWebstoriesData?.data && allWebstoriesData?.data[category] && allWebstoriesData?.data[category].length > 0
      ? allWebstoriesData?.data[category]
      : [];

  const gotoWebstoriesWithId = (id) => {
    navigate(`/webstorydetails?param1=${id}&category=${category}`);
    window.scrollTo(0, 0);
  };
  const handlePagination = (page, pageSize, key) => {
    const payload = {
      interface_language_id: selectedInterfaceLanguage?.interface_lang_id,
      is_all: true,
      is_category: false,
      is_latest: false,
      is_trending: false,
      login_user_id: null,
      news_feed_lang_ids: [60],
      page: page - 1,
      pageSize: pageSize,
      section_name: "",
    };
    if (key === "అన్ని" || key === "All") {
      payload.is_all = true;
      payload.is_latest = false;
      payload.is_trending = false;
      setCategory("All");
    } else if (key === "Latest" || key === "తాజా") {
      payload.is_all = false;
      payload.is_latest = true;
      payload.is_trending = false;
      setCategory("Latest");
    } else if (key === "Trending" || key === "ట్రెండింగ్") {
      payload.is_all = false;
      payload.is_latest = false;
      payload.is_trending = true;
      setCategory("Trending");
    } else {
      payload.is_all = false;
      payload.is_category = true;
      payload.section_name = key;
      setCategory(key);
    }
    console.log("Page:", page, "PageSize:", pageSize, "Key:", key);
    setCurrentPage(page);
    dispatch(getAllWebstoriesData(payload));
    window.scrollTo(0, 0);
  };
  const pageSize = 10;
  const paginatedData = webStoriesData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  return (
    <>
      <div>
        <Row style={{ marginTop: "10px", marginBottom: "20px", padding: "5px", width: "100%" }}>
          <Col>
            {webcategories?.map((type) => {
              return (
                <span
                  key={type.category_name}
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                    fontSize: "16px",
                    cursor: "pointer",
                    padding: "5px 10px",
                    borderRadius: "4px",
                  }}>
                  <span>
                    <strong
                      className={`${selectedInterfaceLanguage?.name === "తెలుగు" ? "telugu-text" : "english-text"}`}
                      style={{
                        color: category === type?.category_name ? "red" : "black",
                      }}
                      onClick={() => gettingData(type?.category_name)}>
                      {type?.category_name}
                    </strong>
                  </span>
                </span>
              );
            })}
          </Col>
        </Row>

        {status === "idle" && webStoriesData?.length === 0 ? (
          <div className="item">
            <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                  marginTop: "100px",
                  marginBottom: "70px",
                }}>
                <Empty />
                <h4 style={{ color: "white" }}>No data </h4>
              </div>
            </div>
          </div>
        ) : (
          <div className="news-cards-wrapper" style={{ marginTop: screens.xs ? "0px" : "-30px" }}>
            {/* <div id="webstory-container" className="news-cards-container"> */}
            <div className="grid-container">
              {webStoriesData?.map((slide, index) => {
                console.log("slide", slide);
                return (
                  <div>
                    <div
                      onClick={() => gotoWebstoriesWithId(slide?.lens_id)}
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "10px",
                        textAlign: "center",
                        overflowX: "auto",
                        width: "225px",
                        height: "375px",
                        objectFit: "cover",
                        marginBottom: "10px",
                      }}>
                      <Image
                        src={slide.url}
                        alt={slide.title || "Story"}
                        layout="fill"
                        objectFit="cover"
                        style={{ filter: "brightness(90%)", width: "225px", height: "375px" }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "10px", // Position dots 10px above the bottom of the image
                          left: "50%", // Center horizontally
                          transform: "translateX(-50%)", // Offset for proper centering
                          display: "flex",
                          flexDirection: "row", // Align dots horizontally
                          gap: "0.5rem", // Add spacing between dots
                          marginTop: "-20px",
                        }}>
                        {[...Array(slide.number_of_images)].map((_, index) => (
                          <div
                            key={index}
                            style={{
                              marginTop: "-70px",
                              background: "#D9D9D9",
                              width: "8px",
                              height: "8px",
                              borderRadius: "30px",
                            }}></div>
                        ))}
                      </div>

                      <div
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          position: "absolute",
                          bottom: "20px",
                          width: "100%",
                        }}>
                        <div
                          style={{
                            position: "absolute",
                            height: "40px",
                            bottom: "0px",
                            left: "0px",
                            right: "0px",
                            backgroundColor: "rgba(0, 0, 0, 0.6)",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "5px",
                            fontSize: "16px",
                          }}>
                          {slide.title}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Row justify="end" style={{ marginTop: "20px" }}>
          <Pagination
            current={currentPage}
            total={allWebstoriesData?.totalElements}
            pageSize={pageSize}
            onChange={(page) => handlePagination(page, pageSize, category)}
            showSizeChanger={false}
          />
        </Row>
      </div>
    </>
  );
};

export default AllwebstoriesList;
