import React, { useEffect, useState, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseUrl from "../../../utils/config";
import {
  Tooltip,
  Empty,
  Badge,
  Row,
  Col,
  Skeleton,
  Carousel,
  Grid,
  Button,
  Avatar,
  Tag,
  Flex,
  message,
  Modal,
} from "antd";
import smileGif from "../../../imgaes/smileGif.gif";
import cryIcon from "../../../imgaes/cryIcon.gif";
import heartIcon from "../../../imgaes/heartIcon.gif";
import angryIcon from "../../../imgaes/angryIcon.gif";
import likeIcon from "../../../imgaes/likeIcon.gif";
import wowIcon from "../../../imgaes/wowIcon.gif";
import noImg from "../../../imgaes/noImg.svg";
import noImgJPG from "../../../imgaes/noImgJPG.jpg";
import defaultChannel from "../../../imgaes/defaultChannel.svg";
import smileIcon from "../../../imgaes/smileIcon.svg";
import messageIcon from "../../../imgaes/messageIcon.svg";
import whatsappIcon from "../../../imgaes/whatsappIcon.svg";
import headphoneIcon from "../../../imgaes/headphoneIcon.svg";
import videoPlayIcon from "../../../imgaes/videoPlayIcon.svg";
import ReactPlayer from "react-player/lazy";
import {
  faBookmark,
  faShareSquare,
  faShareNodes,
  faBan,
  faFlag,
  faComment,
  faEllipsisV,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { addBookmarkLivetv, removeBookmarkLivetv } from "../../../Store/BookmarkedLivetvSlice";
import MorEellipsisIcon from "../../Sections/Common/MorEellipsisIcon";
import Emojis from "../../sharedComponents/Emojis";
import { shareStoryUrl } from "../../../utils/config";
import CommentsModal from "../../sharedComponents/CommentsModal";
import { useDispatch, useSelector } from "react-redux";
// import { getAllTechnologyData } from "../../../Store/TechnologySlice";
import { getAllLivetvData } from "../../../Store/LivetvSlice";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton,
  LinkedinIcon,
  PinterestIcon,
  XIcon,
  TumblrIcon,
  FacebookIcon,
  TelegramIcon,
  FacebookMessengerIcon,
  WhatsappShareButton,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TranslateWord from "../../sharedComponents/TranslateWord";

const { useBreakpoint } = Grid;
const LivetvSection = ({ categoryName, subCategoryName, sectionInterfaceLang }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [splideAutoScroll, setSplideAutoScroll] = useState(true);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedShareInstance, setSelectedShareInstance] = useState({});
  const [isBookmarked, setIsBookmarked] = useState(false);
  // const [selectedRecord, setSelectedRecord] = useState(allLivetvData);
  // const [follow, setFollow] = useState(false);
  // const {
  //   data: allTechnologyData,
  //   status,
  //   error,
  //   technologySelectedLang,
  // } = useSelector((state) => state.technologyList);

  const { data: allLivetvData, status, error, livetvSelectedLang } = useSelector((state) => state.livetvList);
  const [selectedRecord, setSelectedRecord] = useState(
    allLivetvData && allLivetvData?.data && allLivetvData?.data.length ? allLivetvData.data[0] : {}
  );
  const [followsChannel, setFollowsChannel] = useState(selectedRecord?.is_login_user_follows_channel);

  console.log("allLivetvData", allLivetvData);
  const [isPlaying, setIsPlaying] = useState(false); //23-10
  const carouselRef = useRef(null);
  const handlePause = (e) => {
    //23-10
    console.log("value", e);
    setIsPlaying(false);
    //   if (carouselRef.current) {
    //   carouselRef.current.next();
    // }
  };
  const handlePlay = () => {
    //23-10
    setIsPlaying(true);
  };

  useEffect(() => {
    if (allLivetvData && allLivetvData?.data && allLivetvData?.data.length) {
      setSelectedRecord(allLivetvData.data[0]);
    }
  }, [allLivetvData]);

  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  console.log("selectedfeedLanguagejn", selectedfeedLanguage);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  // console.log("allTechnologyData", allTechnologyData);
  // console.log("allTechnologyData", status);
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };

  const gettingData = () => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      console.log("feed", feed);
      return feed?.name === livetvSelectedLang?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }

    const payload = {
      // category_name: "All",
      category_name: categoryName,
      interface_language_id: sectionInterfaceLang?.interface_lang_id,
      // login_user_id: 277, //loggedInUserData?.user_id,
      login_user_id: loggedInUserData?.user_id,
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 10,
      secction_name: null,
      source_id: null,
    };
    if (!loggedInUserData?.user_id) {
      delete payload.login_user_id;
    }
    // if (subCategoryName === "All" || subCategoryName === "అన్ని") {
    //   delete payload.sub_category_name;
    // }
    // if (payload?.news_feed_lang_ids?.length)
    dispatch(getAllLivetvData(payload));
  };
  useEffect(() => {
    gettingData();
  }, [dispatch, livetvSelectedLang, selectedfeedLanguage, categoryName]);

  if (status === "loading") {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  const goToSourceWithId = (id) => {
    navigate(`/livetvdetails?param1=${id}&category=${categoryName}&subcategory=${subCategoryName}`);
    window.scrollTo(0, 0);
  };

  const clickedRecord = (record) => {
    setSelectedRecord(record);
  };

  const goToSourceWitAuthorId = (input_user_id) => {
    window.scrollTo(0, 0);
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };
  const handleEmojiSelect = () => {
    setSplideAutoScroll(false);
  };
  const getLatestData = (data) => {
    gettingData();
  };
  const navigateTo = (rec) => {
    navigate(`/livetvdetails?param1=${rec.id}&category=${categoryName}&subcategory=${subCategoryName}`);
  };

  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };

  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  const politikosLogo = "https://cdn-dev.politikos.cloud/livetv/images/1712566515499-1011556105729521321.jpg";

  const handleBookmarkActions = (bookMarkLivetvId, isUpdate) => {
    // console.log("1234", bookMarkLivetvId, isUpdate);
    if (isUpdate) {
      removeBookmarkToLiveTV(bookMarkLivetvId);
    } else {
      addBookmarkToLivetv(bookMarkLivetvId);
    }
  };

  const addBookmarkToLivetv = async (bookMarkLivetvId) => {
    if (!loggedInUserData?.user_id) {
      message.warning({
        content: "Please Login",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });
      return;
    }
    const payload = {
      login_user_id: loggedInUserData?.user_id,
      // login_user_id: 277,
      livetv_management_id: bookMarkLivetvId ?? 0,
    };
    dispatch(addBookmarkLivetv(payload)).then((action) => {
      if (addBookmarkLivetv.fulfilled.match(action)) {
        message.success({
          content: "Bookmarked successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
        // sendLatestData();
        gettingData();
      }
    });
  };

  const removeBookmarkToLiveTV = async (bookMarkLivetvId) => {
    // const userId = loggedInUserData?.user_id;
    // const storyId = bookMarkStoryId ?? 0;
    const login_user_id = loggedInUserData?.user_id;
    const livetv_management_id = bookMarkLivetvId ?? 0;
    dispatch(removeBookmarkLivetv({ userId: login_user_id, liveTvManagmentId: livetv_management_id })).then(
      (action) => {
        if (removeBookmarkLivetv.fulfilled.match(action)) {
          message.success({
            content: "Removed Bookmark successfully",
            duration: 5,
            style: {
              marginTop: "20px",
              marginBottom: "20px",
            },
          });
          // sendLatestData();
          gettingData();
        }
      }
    );
  };

  const handleShare = () => {
    setIsModalVisible(!isModalVisible);
  };
  const updateShareInstance = (record) => {
    setSelectedShareInstance(record);
    handleShare();
  };

  const followChannel = (selectedRecord) => {
    if (!loggedInUserData?.user_id) {
      message.warning({
        content: "Please Login",
        duration: 5,
        style: {
          marginTop: "20px",
          marginBottom: "20px",
        },
      });
      return;
    }
    const payload = {
      livetv_id: selectedRecord?.source_id,
      login_user_id: loggedInUserData?.user_id,
    };

    axios
      .post(`${BaseUrl}/livetv/follow`, payload)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          gettingData();
          setFollowsChannel(true);
          return response.data;
        }
      })
      .catch((error) => console.log(error));
    // gettingData();
    // setFollow(true);
  };

  const unFollowChannel = (selectedRecord) => {
    const payload = {
      livetv_id: selectedRecord?.source_id,
      login_user_id: loggedInUserData?.user_id,
    };

    axios
      .post(`${BaseUrl}/livetv/unfollow`, payload)
      .then((response) => {
        if (response.status === 200) {
          // window.location.reload();
          gettingData();
          setFollowsChannel(false);
          // return response.data;
        }
      })
      .catch((error) => console.log(error));
    // gettingData();
  };

  const bookmark = livetvSelectedLang?.name === "తెలుగు" ? "బుక్ మార్క్" : "Bookmark";
  const share = livetvSelectedLang?.name === "తెలుగు" ? "షేర్" : "Share";
  const block = livetvSelectedLang?.name === "తెలుగు" ? "బ్లాక్" : "Block";
  const comments = livetvSelectedLang?.name === "తెలుగు" ? "వ్యాఖ్యలు" : "Comments";
  const follow = livetvSelectedLang?.name === "తెలుగు" ? "అనుసరించండి" : "Follow";
  const unfollow = livetvSelectedLang?.name === "తెలుగు" ? "అనుసరించవద్దు" : "Unfollow";
  const suggestedlives = livetvSelectedLang?.name === "తెలుగు" ? "సూచించిన లైవ్‌లు" : "Suggested Lives";
  return (
    <>
      {status === "idle" && allLivetvData?.data === null ? (
        <div className="item" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
          <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop: "100px",
                marginBottom: "70px",
              }}>
              <Empty />
              <h4 style={{ color: "white" }}>No data </h4>
            </div>
          </div>
        </div>
      ) : (
        <>
          {status === "idle" && (
            <>
              <Row
                style={{
                  height: !screens.xs ? "550px" : "auto",
                }}
                gutter={[16, 16]}>
                <Col xs={24} sm={13} md={15} lg={15} xl={15} xxl={15}>
                  <div
                    style={{
                      height: !screens.xs ? "75%" : "auto",
                    }}>
                    <ReactPlayer
                      width={"100%"}
                      height={"100%"}
                      muted={true}
                      controls={true}
                      url={selectedRecord?.video_link}
                    />
                  </div>
                  <div
                    style={{
                      height: !screens.xs ? "25%" : "auto",
                    }}>
                    <div className="carousel_image_title">
                      <h2 className="title-medium-light size-lg">
                        <Tooltip title={selectedRecord?.video_title} color="gray">
                          <strong
                            style={{
                              cursor: "pointer",
                              color: "white",
                            }}
                            onClick={() => {
                              navigateTo(selectedRecord?.id);
                            }}
                            className={`hoverTitle ${
                              selectedRecord?.language === "తెలుగు" ? "telugu-text-title" : "english-text-title"
                            }`}>
                            {selectedRecord?.video_title?.length > 50
                              ? selectedRecord?.video_title?.substring(0, 50) + "..."
                              : selectedRecord?.video_title}
                          </strong>
                        </Tooltip>
                      </h2>
                      <div className="livetv-action-buttons">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            position: "relative",
                            marginTop: "10px",
                            marginBottom: "15px",
                          }}>
                          <div>
                            <span>
                              {selectedRecord?.source_name_image_url ? (
                                <Avatar src={selectedRecord?.source_name_image_url} size={30} alt="img" />
                              ) : (
                                <Avatar src={defaultChannel} size={30} alt="default Channel" />
                              )}
                            </span>
                            <Link
                              to={`/GotoSource?param1=${selectedRecord?.source_id}&activeTab=${"Latest"}`}
                              style={{
                                color: "white",
                                position: "relative",
                                top: "3px",
                                left: "3px",
                              }}>
                              {selectedRecord?.source_name}
                            </Link>
                          </div>
                          <div style={{ marginRight: "15px" }}>
                            {selectedRecord?.is_login_user_follows_channel ? (
                              <Button
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                  borderRadius: "15px",
                                  marginLeft: "20px",
                                }}
                                onClick={() => {
                                  unFollowChannel(selectedRecord);
                                }}>
                                {unfollow}
                              </Button>
                            ) : (
                              <Button
                                style={{
                                  backgroundColor: "white",
                                  color: "black",
                                  borderRadius: "15px",
                                  marginLeft: "20px",
                                }}
                                onClick={() => {
                                  followChannel(selectedRecord);
                                }}>
                                {follow}
                              </Button>
                            )}
                          </div>
                        </div>

                        <div
                          className="channel-bookmark-container"
                          style={{ position: "relative", overflowX: "auto", scrollBehavior: "smooth" }}>
                          <Button
                            style={{
                              backgroundColor: "transparent",
                              borderColor: "#FFFFFF",
                              borderRadius: "15px",
                              color: "#FFFFFF",
                              marginRight: "10px",
                            }}
                            onClick={() => handleBookmarkActions(selectedRecord?.id, selectedRecord?.is_book_marked)}>
                            {selectedRecord?.is_book_marked === true ? (
                              <i
                                class="fa fa-bookmark"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "red",
                                }}></i>
                            ) : (
                              <i
                                class="fa fa-bookmark-o"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "#FFFFFF",
                                }}></i>
                            )}
                            {bookmark}
                          </Button>
                          <Button
                            style={{
                              backgroundColor: "transparent",
                              borderColor: "#FFFFFF",
                              color: "#FFFFFF",
                              borderRadius: "15px",
                              fontWeight: "400",
                              marginRight: "10px",
                            }}
                            onClick={() => updateShareInstance(selectedRecord)}>
                            {" "}
                            <i
                              className="fa fa-share-alt"
                              aria-hidden="true"
                              style={{
                                fontSize: "20px",
                                color: "#FFFFFF",
                              }}></i>
                            {share}
                          </Button>
                          <Tooltip title="Comming Soon">
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "#FFFFFF",
                                color: "#FFFFFF",
                                borderRadius: "15px",
                                marginRight: "10px",
                              }}>
                              {" "}
                              <i
                                className="fa fa-ban"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "#FFFFFF",
                                }}></i>{" "}
                              {block}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Comming Soon">
                            <Button
                              style={{
                                backgroundColor: "transparent",
                                borderColor: "#FFFFFF",
                                color: "#FFFFFF",
                                borderRadius: "15px",
                                marginRight: "10px",
                                padding: "-10px",
                              }}>
                              {" "}
                              <i
                                className="fa fa-comments-o"
                                aria-hidden="true"
                                style={{
                                  fontSize: "20px",
                                  color: "#FFFFFF",
                                }}></i>
                              {comments}
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <Modal className="share_more_modal" title="Share More" open={isModalVisible} onCancel={handleShare}>
                      <div>
                        <Row gutter={16}>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <FacebookShareButton
                              url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}
                              // className="Demo__some-network__share-button"
                            >
                              <FacebookIcon size={25} round /> Facebook
                            </FacebookShareButton>
                          </Col>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <TwitterShareButton
                              url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}
                              title={selectedShareInstance.video_title}
                              // className="Demo__some-network__share-button"
                            >
                              <XIcon size={25} round />
                              &nbsp;Twitter
                            </TwitterShareButton>
                          </Col>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <TelegramShareButton
                              url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}
                              title={selectedShareInstance.video_title}
                              // className="Demo__some-network__share-button"
                            >
                              <TelegramIcon size={25} round />
                              &nbsp;Telegram
                            </TelegramShareButton>
                          </Col>
                          <Col span={12} style={{ marginBottom: "10px" }}>
                            <LinkedinShareButton
                              url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}
                              // className="Demo__some-network__share-button"
                            >
                              <LinkedinIcon size={25} round /> Linkedin
                            </LinkedinShareButton>
                          </Col>
                          <Col span={12}>
                            <TumblrShareButton url={`${shareStoryUrl}/about?param1=${selectedShareInstance?.id}`}>
                              <TumblrIcon size={25} round /> Tumblr
                            </TumblrShareButton>
                          </Col>
                        </Row>
                      </div>
                    </Modal>
                    {showCommentsDrawer && (
                      <CommentsModal
                        showDrawer={showCommentsDrawer}
                        closeDrawer={handleCloseDrawer}
                        story={storyRecord}
                      />
                    )}
                  </div>
                  {/* <Row >
                    <Col span={24}>
                    
                    </Col>
                  </Row>
                  <Row >
                    <Col span={24}>
                    
                    </Col>
                  </Row> */}
                </Col>
                <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                  <div className="live-uparr-dwnarr">
                    <Splide
                      options={{
                        rewind: true,
                        autoplay: autoPlayStatus,
                        gap: "1rem",
                        // gap: "-85px",          // for small divices gap:-15px
                        direction: "ttb",
                        height: "50rem",

                        type: allLivetvData?.data?.length <= 3 ? "slide" : "slide",
                        drag: "free",
                        perPage: screens.xs ? 4 : 5,
                        autoScroll: {
                          pauseOnHover: true,
                          pauseOnFocus: true,
                          rewind: false,
                          speed: 1,
                          enabled: splideAutoScroll, // Enable or disable auto-scroll based on the state
                        },
                      }}>
                      <>
                        <h3>{suggestedlives}</h3>
                        {allLivetvData?.data?.map((record) => {
                          return (
                            <SplideSlide style={{ marginLeft: "8px" }}>
                              <div className="item tiles_slider">
                                <div className="media" style={{ cursor: "pointer" }}>
                                  <div
                                    // className="width38-lg width40-md img-opacity-hover"
                                    // onClick={() => {
                                    //   goToSourceWithId(record?.id);
                                    // }}

                                    onClick={() => {
                                      clickedRecord(record);
                                    }}>
                                    {record?.video_link && record?.video_thumbnail_url?.[0] ? (
                                      <>
                                        <span>
                                          <img style={imageStyle} src={videoPlayIcon} />
                                        </span>{" "}
                                        <img
                                          src={record?.video_thumbnail_url}
                                          onClick={() => {
                                            clickedRecord(record);
                                          }}
                                          alt="news"
                                          style={{
                                            display: "block",
                                            margin: "0 auto",
                                            width: "150px",
                                            height: "85px",
                                            borderRadius: "8%",
                                            objectFit: "cover",
                                            // backgroundColor:'#000000'
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={noImgJPG}
                                          alt="No-Image"
                                          style={{
                                            display: "block",
                                            margin: "0 auto",
                                            // maxWidth: "100%",
                                            //  maxHeight: "32vh",
                                            width: "150px",
                                            height: "85px",
                                            borderRadius: "8%",
                                            objectFit: "cover",
                                            // backgroundColor:'#000000'
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                  <div className="media-body">
                                    <h3
                                      className={`title-medium-dark size-md mb-none ${
                                        record?.language === "తెలుగు" ? "telugu-text" : "english-text"
                                      }`}
                                      onClick={() => {
                                        clickedRecord(record);
                                      }}
                                      style={{ color: "white" }}>
                                      {record?.video_title?.length > 60
                                        ? record?.video_title?.substring(0, 60) + "..."
                                        : record?.video_title}
                                    </h3>
                                    <Row>
                                      <Col span={15}>
                                        <li
                                          key={record?.id}
                                          onClick={() => {
                                            clickedRecord(record);
                                          }}>
                                          <span>
                                            <Avatar
                                              src={record?.source_name_image_url}
                                              height={5}
                                              width={5}
                                              alt="img"
                                            />
                                          </span>
                                          &nbsp;
                                        </li>
                                      </Col>
                                      <Col span={7}>
                                        <Button className="live-button">Live</Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </SplideSlide>
                          );
                        })}
                      </>
                    </Splide>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default LivetvSection;
