import React, { useEffect, useState } from "react";
import { Tabs, Select, Row, Col, Grid } from "antd";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
import { getAllEntertainmentData, selectedEntertainmentInterfaceAction } from "../../../Store/EntertainmentSlice";
import EntertainmentSection from "./EntertainmentSection";

const { useBreakpoint } = Grid;
const EntertainmentSectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const { entertainmentSelectedLang } = useSelector((state) => state.entertainmentList);
  console.log("entertainmentSelectedLang", entertainmentSelectedLang);

  let filterCategory = entertainmentSelectedLang?.name === "తెలుగు" ? "వినోదం" : "Entertainment";
  const ALLWORD = entertainmentSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);

  const { Option } = Select;
  useEffect(() => {
    setActiveKey(ALLWORD);
    const selectedCategoriesList = allCategoriesList?.find((item) => item.category_name === filterCategory);
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length) setAllSubCategoriesList([ALLWORD, ...subCategories]);
  }, [allCategoriesList, filterCategory]);
  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
  };
  const handleChange = (value) => {
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    dispatch(selectedEntertainmentInterfaceAction(selectedIngerfaceLang?.[0]));
    const payload = {
      category_name: "Entertainment",
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 9,
    };
  };
  return (
    <>
      <div
        style={{ position: "relative", top: "10px" }}
        className={
          entertainmentSelectedLang?.name === "తెలుగు" ? "custom-politics-tabs-telugu" : "custom-politics-tabs-english"
        }>
        <Row justify={"space-between"}>
          <Col span={4}>
            <div className="heading headig_technology politics_heading">
              <h1>
                <Link to={`/${filterCategory}`}>{sectionType}</Link>
              </h1>
            </div>
          </Col>
          <Col span={screens.xs ? 10 :8}>
            <div style={{ textAlign:'end',marginTop: entertainmentSelectedLang?.name === "తెలుగు" ? "-2%" : "0px"}}>
            <span  className="viewall_style">
                <Link to={`/${filterCategory}`}>
                  {
                    <TranslateWord
                      key={"VIEWALL"}
                      text={"VIEWALL"}
                      headingStyle={false}
                      lang={entertainmentSelectedLang}
                    />
                  }
                </Link>
              </span>
             
              &emsp; &emsp;
              {entertainmentSelectedLang && (
                <Select
                  className="hide-on-small feedDropdown"
                  size="small"
                  defaultValue={entertainmentSelectedLang && entertainmentSelectedLang?.interface_lang_id}
                  onChange={handleChange}>
                  {interfaceLanguagesData?.map((lang) => {
                    return (
                      <>
                        <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                      </>
                    );
                  })}
                </Select>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              className={screens.xs ? "custom-tabs-container" : ""}
              moreIcon={
                <span style={{ display: "flex" }}>
                  <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={entertainmentSelectedLang} />
                  &#11206;
                </span>
              }>
              {allSubCategoriesList?.map((tabrecord, index) => {
                const tabKey = tabrecord;
                return (
                  <>
                    <TabPane
                      tab={
                        <span className={entertainmentSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                          {tabrecord}
                        </span>
                      }
                      key={tabKey}>
                      <EntertainmentSection
                        key={index + tabKey}
                        subCategoryName={activeKey}
                        categoryName={filterCategory}
                        sectionInterfaceLang={entertainmentSelectedLang}
                      />
                    </TabPane>
                  </>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EntertainmentSectionDB;
